// @see: https://codepen.io/Jossafossa/pen/gOXNNJE?editors=0010

export default class ScrollDirection {
	constructor( params = {} ) {
		// override default settings
		this.settings = Object.assign(
			{
				classContainer: document.body,
				scrollElement: window,
				scrollDownClass: 'scroll-down',
				scrollUpClass: 'scroll-up',
				scrollTopClass: 'scroll-top',
				scrollBottomClass: 'scroll-bottom',
				offsetTop: 0,
				buffer: 2,
			},
			params
		);

		// setup inital vars
		this.container = this.settings.classContainer;
		this.root = this.settings.scrollElement;
		this.buffer = this.settings.buffer;
		this.prev = this.getScroll( this.root );
		this.previous = [];

		// convert container to array if it's not already
		if ( ! Array.isArray( this.container ) ) {
			this.container = [ this.container ];
		}

		// scroll event
		this.root.addEventListener( 'scroll', () => this.update() );

		// init update
		this.update();
	}

	add( customClass ) {
		this.container.forEach( ( e ) => e.classList.add( customClass ) );
	}

	remove( customClass ) {
		this.container.forEach( ( e ) => e.classList.remove( customClass ) );
	}

	update() {
		// get scroll position
		const scroll = this.getScroll( this.root );

		// keep track of the previous scroll position
		this.previous.push( scroll );

		// if the previous scroll position is more than 10, remove the oldest one
		if ( this.previous.length > this.buffer ) {
			this.previous.shift();
		}

		// if the previous scroll positions are in ascending order
		const isAscending = this.previous.every( ( e, i, a ) => i === 0 || e >= a[ i - 1 ] );
		const isDecending = this.previous.every( ( e, i, a ) => i === 0 || e <= a[ i - 1 ] );

		if ( ! ( ! isAscending && ! isDecending ) ) {
			// determine the scroll direction and add classes accordingly
			if ( this.prev > scroll || scroll <= this.settings.offsetTop ) {
				this.remove( this.settings.scrollDownClass );
				this.add( this.settings.scrollUpClass );
			} else if ( this.prev < scroll ) {
				this.add( this.settings.scrollDownClass );
				this.remove( this.settings.scrollUpClass );
			}
		}

		if ( scroll - this.settings.offsetTop <= 0 ) {
			this.add( this.settings.scrollTopClass );
		} else {
			this.remove( this.settings.scrollTopClass );
		}

		if (
			this.root.offsetHeight + this.root.scrollTop >=
			this.root.scrollHeight - 1
		) {
			this.add( this.settings.scrollBottomClass );
		} else {
			this.remove( this.settings.scrollBottomClass );
		}

		// save the previous scroll
		this.prev = scroll;
	}

	// get the scroll position of the root element.
	// Chooses between scrollY for the window and scrollTop for HTMLElements
	getScroll( e ) {
		return e instanceof Window ? e.scrollY : e.scrollTop;
	}
}
