
/**
 * Load external dependencies.
 */
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

/**
 * Load internal dependencies.
 */
import Drilldown from './_vendor/drilldown/drilldown';
import createSwiper from './components/swiper';
// import createGLightbox from './components/glightbox';
// import './components/wcag.js';
import ScrollDirection from './helpers/scrollDirection';
import CourseSelector from './components/course-selector.js';

import StripeEffect from './_vendor/stripeEffect.js';
import ScrollSpy from './_vendor/ScrollSpy';

/**
 * Initialize javascript functionality.
 */
const init = async () => {
	/**
	 * Blocks
	 */

	// Maps.
	const mapsBlock = document.querySelector( '.block-map' );
	if ( mapsBlock ) {
		import( './blocks/block-maps.js' ).then( ( { default: InitBlock } ) => new InitBlock() );
	}

	new StripeEffect( '.has-underline, .has-underline-links a' );

	new ScrollDirection( {
		// scrollElement: document.body,
		offsetTop: 0,
	} );

	/**
	 * Drilldown menu.
	 *
	 */
	const drilldowns = document.querySelectorAll( '.drilldown' );
	for ( const drilldown of drilldowns ) {
		new Drilldown( drilldown );
	}

	/**
	 * Toggle preload classes.
	 */
	document.body.classList.remove( 'preload' );
	document.body.classList.add( 'animation-preload' );
	setTimeout( () => document.body.classList.remove( 'animation-preload' ), 1000 );

	/**
	 * Create Swiper, (Navigation, Pagination, Thumbs, Controller) are already inserted.
	 */
	createSwiper( '.swiper-slider', {
		slidesPerView: 2.5,
		spaceBetween: 20,
		breakpoints: {
			0: {
				slidesPerView: 1.2,
				spaceBetween: 20,
			},
			800: {
				slidesPerView: 1.5,
				spaceBetween: 20,
			},
			1600: {
				slidesPerView: 2.5,

			},
		},
		navigation: {
			nextEl: '.button-next',
			prevEl: '.button-prev',
		},
	} );

	// init lazy loaded ajax filter javscript
	const ajaxFilters = document.querySelectorAll( '[data-b2-filter]' );
	if ( ajaxFilters.length > 0 ) {
		import( /* webpackChunkName: "ajax-filter" */ './components/b2-ajax-filter' ).then( ( { default: AjaxFilter } ) => {
			for ( const ajaxFilter of ajaxFilters ) {
				new AjaxFilter( ajaxFilter );
			}
		} );
	}

	// init lazy loaded maps
	const maps = document.querySelectorAll( '.block-maps' );
	for ( const map of maps ) {
		import(
			/* webpackChunkName: "maps" */ './blocks/block-maps'
		).then( ( { default: Init } ) => new Init( map ) );
	}

	const scrollSpies = document.querySelectorAll( '[data-scroll-spy]' );
	for ( const target of scrollSpies ) {
		new ScrollSpy( window, {
			target,
		} );
	}

	// sticky
	const sticky = document.querySelector( '.sticky' );
	if ( sticky ) {
		( async function() {
			const { default: Sticky } = await import( /* webpackChunkName: "sticky" */ 'sticky-js' );
			window.sticky = new Sticky( '.sticky', {
				stickyFor: 992,
				wrap: true,
			} );
		}() );
	}

	// variation-selector
	const variationSelectors = document.querySelectorAll( '[data-variation-selector]' );
	const variations = document.querySelectorAll( '[data-variation-content]' );

	const updateVariationContent = ( variation ) => {
		for ( const content of variations ) {
			if ( content.dataset.variationContent === variation ) {
				content.style.display = 'block';
			} else {
				content.style.display = 'none';
			}
		}

		if ( window?.sticky ) {
			window.sticky.update();
		}
	};

	for ( const selector of variationSelectors ) {
		selector.addEventListener( 'change', ( e ) => {
			const variation = e.target.value;
			updateVariationContent( variation );
		} );
		updateVariationContent( selector.value );
	}

	// course selector
	const courseSelectors = document.querySelectorAll( '[data-course-selector]' );
	for ( const selector of courseSelectors ) {
		new CourseSelector( selector );
	}

	const hero = document.querySelector( '[article-sidebar]' );
	if ( hero ) {
		const update = () => {
			const box = hero.getBoundingClientRect();
			document.body.style.setProperty( '--sidebar-height', box.height + 'px' );
		};
		new ResizeObserver( ( e ) => update( e[ 0 ].contentRect )	).observe( hero );
		update();
	}
};

export default init;
