
import { doRequest } from '@/js/helpers/request.js';

export default class CourseSelector {
	constructor( root ) {
		this.root = root;
		this.course = this.root.querySelector( '[data-course]' );
		this.courseID = this.root.dataset.courseSelector;
		this.variation = this.root.querySelector( '[data-variation]' );
		this.data = document.querySelectorAll( `[data-variation-data=${ this.courseID }]` );
		this.init();
	}

	async getVariations( course ) {
		if ( this.controller ) {
			this.controller.abort();
		}
		this.controller = new AbortController();

		return await doRequest( {
			action: 'get_variations',
			course,
		}, this.controller.signal );
	}

	setVariations( variations ) {
		this.variation.innerHTML = variations.options;
	}

	setVariationData( variation ) {
		for ( const item of this.data ) {
			item.innerHTML = variation;
		}
	}

	addLoading() {
		this.variation.setAttribute( 'disabled', 'disabled' );
	}

	removeLoading() {
		this.variation.removeAttribute( 'disabled' );
	}

	async update( courseID ) {
		this.addLoading();
		this.variations = await this.getVariations( courseID );

		const variationValue = this.variation.value;

		this.setVariations( this.variations );
		this.setVariationValue( variationValue );
		this.setVariationData( this.variation.value );
		this.removeLoading();
	}

	setVariationValue( value ) {
		// if value is not available bail
		if ( ! this.variations.attributes[ value ] ) {
			return;
		}

		this.variation.value = value;
	}

	async init() {
		const updateVariation = () => {
			const value = this.variation.value;
			const data = this.variations.attributes[ value ] || '';

			this.setVariationData( data );
		};

		this.course.addEventListener( 'change', ( ) => {
			this.update( this.course.value );
		} );
		await this.update( this.course.value );
		updateVariation( this.variation.value );

		this.variation.addEventListener( 'change', ( ) => updateVariation() );
	}
}
