/* global b2 */

/**
 * Make AJAX request to WordPress.
 *
 * @param {RequestInit}     [options]    Fetch options.
 * @param {AbortController} [controller] Controller for current request.
 *
 * @throws Will throw an error if the status code is not 2xx.
 * @throws Will throw data if the WordPress success status is false.
 *
 * @return {Promise<any>} Resolved data.
 */
export async function doRequest( options = {}, controller = null ) {
	const response = await fetch( b2.ajax_url, {
		// eslint-disable-line camelcase
		method: 'POST',
		signal: controller.signal,
		body: new URLSearchParams( {
			...options,
			security: b2.nonce, // eslint-disable-line camelcase
		} ),
	} );

	// Make sure we have a 2xx status code.
	if ( ! response.ok ) {
		throw new Error( 'Response is not ok!' );
	}

	// Get JSON response.
	const { success, data } = await response.json();

	// Make sure the response is successful.
	if ( ! success ) {
		throw data;
	}

	// Return data.
	return data;
}

export function submitFormWithParams( form, includeParams ) {
	const formData = new FormData( form );
	const entries = Object.fromEntries( formData.entries() );
	const action = form.action || false;

	// get all includeParams from the formData
	const data = {};
	for ( const includeKey of includeParams ) {
		const param = entries[ includeKey ] || false;
		if ( param !== false ) {
			data[ includeKey ] = param;
		}
	}

	// add it to a url and redirect
	const url = new URL( action );
	for ( const [ key, value ] of Object.entries( data ) ) {
		url.searchParams.set( key, value );
	}

	// update the url with reload
	// console.log( 'goto: ', url.href );
	window.location.href = url.href;
}
